div#add-new {
  max-width: 1100px !important;
  margin-top: 6%;
}
div.pick-a-temple {
  width: 40%;
  margin-left: 30%;
}
div.new-modal-body {
  padding: 0rem 1.5rem 1.5rem 1.5rem !important;
}
label.text-center {
  text-align: center;
}
div.align-btn {
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

