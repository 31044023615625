.home-card {
  min-height: 170px;
}
.values:hover {
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
}
.values {
  text-align: center;
}
.spinner-border {
  margin: auto;
  border: .25em solid #fff;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;
}
