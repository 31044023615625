.pointer-cursor {
  cursor: pointer;
  user-select: none;
}

.spinner-container {
  position: absolute;
  width: 100%;
  height: 100%;
  // background-color: #4c4c4c52;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
  animation: spinner-grow .75s linear infinite;
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }
}

.react-bootstrap-table th {
  color: #212529;
  background-color: #f6f9fc;
}

.react-bootstrap-table .order-4:after,
.react-bootstrap-table .order-4:before {
  position: absolute;
  bottom: 1.1em;
  display: block;
  opacity: 0.6;
}

.react-bootstrap-table th{

  position: sticky !important;
  top: 0;
  z-index: 1020;
}