//
// Card
//

.card {
    margin-bottom: 30px;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    border: 0;
}

.card-translucent {
    background-color: rgba(18, 91, 152, 0.08);
}