.btn {
  .btn-inner--icon {
    margin-right: 4px;
  }
}
.btn + .btn {
  margin-left: 4px;
}

.home__col {
  margin: 0 auto !important;
}

.home__container {
  background: rgb(17, 147, 170)
    linear-gradient(rgb(17, 147, 170), rgb(18, 84, 128));
  opacity: 0.8;
  height: 100vh;
  padding-top: 7rem;
}


.login__button{
  text-decoration: none;
  background: white;
  color: rgb(18, 84, 128);
  padding: .5rem 4rem;
  text-transform: uppercase;
  border-radius: 1rem;
  
}

.login__button:hover{
  background: rgb(96, 207, 226);
  color: white;
}

.heading__text{
  font-size: 4rem;
}

.button__container{
  padding-top: 2rem;
}